import ImgMTC from '../../assets/img/clients/mind-therapy.svg';
import ImgDSI from '../../assets/img/clients/dsi.png';
import ImgCriot from '../../assets/img/clients/criot.png';
import ImgMyChatri from '../../assets/img/clients/mychatri.jpg';
import ImgInfiswift from '../../assets/img/clients/infiswift-solutions.jpg';
import ImgTavisca from '../../assets/img/clients/tavisca.jpeg';
import ImgEnquero from '../../assets/img/clients/enquero.png';
import ImgEnergetics from '../../assets/img/clients/energetics-logo.svg';
import ImgNYT from '../../assets/img/clients/nyt-logo.jpg';
import ImgAccenture from '../../assets/img/clients/accenture.png';
import ImgPrizeLogic from '../../assets/img/clients/prizelogic.svg';
import ImgEllieMae from '../../assets/img/clients/ellie-mae.jpeg';

// eslint-disable-next-line import/prefer-default-export
export const clients = {
  title: 'clients.title',
  desc: 'clients.desc',
  clients: [
    {
      url: 'https://www.mindtherapyclinic.com',
      image: ImgMTC,
      alt: 'mtc',
      title: 'Mind Therapy Clinic',
      desc: 'clients.desc.0',
    },
    {
      url: 'https://digitalspacesinc.com',
      image: ImgDSI,
      alt: 'dsi',
      title: 'Digital Spaces Inc',
      desc: 'clients.desc.1',
    },
    {
      url: 'https://www.criotsolutions.com',
      image: ImgCriot,
      alt: 'criot',
      title: 'CRIoT Solutions',
      desc: 'clients.desc.2',
    },
    {
      url: 'https://mychatri.com',
      image: ImgMyChatri,
      alt: 'mychatri',
      title: 'MyChatri',
      desc: 'clients.desc.3',
    },
    {
      // eslint-disable-next-line no-script-url
      url: 'javascript:void(0)',
      image: ImgInfiswift,
      alt: 'infiswift',
      title: 'Infiswift Solutions',
      desc: 'clients.desc.4',
    },
    {
      url: 'https://www.tavisca.com',
      image: ImgTavisca,
      alt: 'tavisca',
      title: 'Tavisca',
      desc: 'clients.desc.5',
    },
    {
      url: 'https://enquero.com',
      image: ImgEnquero,
      alt: 'enquero',
      title: 'Enquero',
      desc: 'clients.desc.6',
    },
    {
      url: 'https://energetics-iot.com',
      image: ImgEnergetics,
      alt: 'energetics-iot',
      title: 'Energetics IOT',
      desc: 'clients.desc.7',
    },
    {
      url: 'https://www.nytimes.com',
      image: ImgNYT,
      alt: 'nyt',
      title: 'New York Times',
      desc: 'clients.desc.8',
    },
    {
      url: 'https://www.accenture.com',
      image: ImgAccenture,
      alt: 'accenture',
      title: 'Accenture',
      desc: 'clients.desc.9',
    },
    {
      url: 'https://www.prizelogic.com',
      image: ImgPrizeLogic,
      alt: 'prizelogic',
      title: 'Prize Logic',
      desc: 'clients.desc.10',
    },
    {
      url: 'https://www.icemortgagetechnology.com',
      image: ImgEllieMae,
      alt: 'ellie-mae',
      title: 'Ellie Mae',
      desc: 'clients.desc.11',
    },
  ],
};
